import React from 'react';
import PropTypes from 'prop-types';
//Components
import Input from '../Partials/Input';
//Functions
import { formatFieldId } from '../Utilities/Helper';
//PropTypes
const proptypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
/**
 * Hidden field
 * @param {proptypes} param0
 * @returns
 */
const HiddenField = ({ field, onChange, rootClass = 'hidden-field' }) => {
    const name = 'input_' + formatFieldId(field.id);
    const additionalParams = {};
    additionalParams['value'] = field.defaultValue;

    return (
        <div className={`field ${rootClass} ${field.cssClass}`}>
            <Input
                type="hidden"
                name={name}
                cssClass={`${rootClass}__input`}
                handleOnChange={e => {
                    onChange(name, e.target.value);
                }}
            />
        </div>
    );
};
HiddenField.propTypes = proptypes;
export default HiddenField;
