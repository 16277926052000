import React from 'react';

const Input = ({
    type,
    handleOnChange,
    name,
    cssClass,
    additionalParams = {},
}) => {
    return (
        <input
            type={type}
            onChange={handleOnChange}
            className={cssClass}
            name={name}
            {...additionalParams}
        />
    );
};
export default Input;
